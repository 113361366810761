.top-navigation-selector {
    position: relative;
    display: inline-block;
    background: left center no-repeat;
    background-size: 20px;
    z-index: 1;
    cursor: pointer;
    line-height: 20px;
    font-size: 16px;
    color: rgb(225, 225, 223);
    font-family: futura-pt, Helvetica, arial, sans-serif;
    font-weight: 400;
    outline: none;
}

.top-navigation-selector__actual {
    display: flex;
    align-items: center;
    padding: 0 15px;
    background: left center no-repeat;
    background-size: 20px;
}

.top-navigation-selector__actual.language-selector {
    padding-top: 3px !important;
    padding-right: 3px !important;
}

.top-navigation-selector__actual-label {
    color: var(--upm-green);
}

.top-navigation-selector__actual-value {
    text-transform: uppercase;
    display: block;
    margin-left: 5px;
    max-width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media (max-width: 1200px) {
    .company-application .top-navigation-selector__actual-value {
        display: none
    }
}

@media (max-width: 480px) {
    .top-navigation-selector:first-child .top-navigation-selector__actual {
        padding: 0;
    }
}

.top-navigation-selector.showMenu .navigation__chevron {
    transform: rotate(-180deg);
}

.top-navigation-selector.showMenu .top-navigation-selector__menu {
    display: block;
}

/*
 *  SCROLLBAR STYLING
 */
.scrollable-selector {
    max-height: 400px !important; 
    overflow: auto;
    scroll-margin: 50px 0 0 50px;
}

 .scrollable-selector::-webkit-scrollbar-track
 {
     -webkit-box-shadow: none;
     border-radius: 6px;
     padding-top:13px;
     background-color: white;
 }
 
 .scrollable-selector::-webkit-scrollbar
 {
     width: 12px;
     background-color: white;
 }
 
 .scrollable-selector::-webkit-scrollbar-thumb
 {
     border-radius: 6px;
     padding-top:13px;
     -webkit-box-shadow: none;
     background-color: #d8dadb;
     border: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
 }

.top-navigation-selector__menu {
    display: none;
    z-index: 1;
    background-color: #fff;
    color: #1a181d;
    position: absolute;
    top: calc(100% + 17px);
    padding: 0;
    border-radius: 4px;
    box-shadow: var(--dropdown-box-shadow);
    transform-origin: 50% 0 0;
    height: auto;
    line-height: 1.25;
    list-style: none;
    left: 50%;
    transform: translateX(-50%);
}
.top-navigation-selector__menu--item {
    height: auto;
    margin: 0 5px;
    white-space: nowrap;
}
.top-navigation-selector__menu--item:not(:last-child) {
    border-bottom: 1px solid #ededed;
}
.top-navigation-selector__menu--item a {
    display: block;
    padding: 10px 20px;
    color: #3d3d3d;
    font-family: futura-pt, Helvetica, arial, serif;
    font-size: 17px;
    text-transform: capitalize;
}
.top-navigation-selector__menu--item.selected a {
    color: var(--upm-green);
    text-decoration: none;
    font-weight: 500;
}
.top-navigation-selector__menu--item:hover a {
    color: #000;
}

.navigation__chevron-container {
    display: inline-block;
    height: 12px;
    width: 12px;
    padding: 0 3px;
    margin-left: 5px;
    margin-top: -5px;
}
.navigation__chevron-container.navigation__chevron--medium {
    width: 18px;
    padding: 0 5px;
}
.navigation__chevron-container.navigation__chevron--medium .navigation__chevron {
    height: 12px;
    width: 12px;
    transform-origin: 50% 55%;
}
.navigation__chevron-container.navigation__chevron--medium .navigation__chevron:after {
    top: 40%;
    height: 7px;
    width: 7px;
    transform: translate(-50%, -60%) rotate(45deg);
}
.company-application .navigation__chevron {
    height: 100%;
    width: 100%;
    display: block;
    transition: transform 0.2s;
    position: relative;
    transform-origin: 50% 65%;
}
.company-application .navigation__chevron:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    height: 8px;
    width: 8px;
    border-style: solid;
    border-width: 1px;
    border-color: transparent currentColor currentColor transparent;
    transform: translate(-50%, -50%) rotate(45deg);
}
