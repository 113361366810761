.home_page_section, .home_page_section-login {
    /*box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);*/
    margin-top: 20px;
	padding: 40px 40px 40px 40px;
	margin-top:100px;
}

.home_page_section {
	height: 50%;
}

.home_page_section-login {
	height: 30%;
}


.home-page-section-text, .home-page-section-text-small {
    font-weight: 400;
    font-family: futura-pt, Helvetica, arial, sans-serif !important;
}

.home-page-section-text {
    font-size: 26px;
}

.home-page-section-text-small {
    font-size: 18px;
}

.home-page-section-link {
    color: rgb(122, 186, 40);
    font-weight: 400;
    font-size: 26px;
    font-family: futura-pt, Helvetica, arial, sans-serif !important;
}

.home-page-section-link:hover {
    text-decoration: none;
}

.home-section-image, .home-section-image-big {
    display:block;
    margin: 20px auto 20px auto;
}

.home-section-image {
    width: 50px;
    height: 50px;
}

.home-section-image-big {
    width: 200px;
    height: 200px;
}

.home-section-image-small {
    width: 20px;
    height: 20px;
    display:inline;
    margin-right: 10px;
}