.hero-wrap {
    width: 100%;
}

.hero {
    display: block;
    width: 100%;
    position: relative;
    overflow: hidden;
    max-width: 1885px;
    margin: 0 auto;
}

.hero .btn--login {
    font-weight: 700;
}

.hero .hero__header {
    font-weight: 700;
}

.hero .hero__content-image {
    background-size: cover;
    background-position: 50%;
    min-height: 180px;
    max-height: 650px;
    transition: opacity .3s;
}

.hero .hero__content-image:after {
    content: "";
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: block;
    height: 0;
    padding-top: 56.25%;
}

.hero.hero--scale .hero__content-image {
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: auto;
    max-height: 100%;
    max-width: 100%;
}

.hero.hero--scale .hero__content-image:after {
    padding-top: 0;
}

.hero .hero__content-wrapper {
    position: relative;
    max-width: 1170px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.hero .hero__content-text {
    position: relative;
    text-align: center;
    width: 90%;
    max-width: 670px;
    margin: 0 auto;
}

.hero .hero__header {
    color: #1a181d;
    margin-top: 10px;
    margin-bottom: 10px;
}

.component-page__description,
p.ingress {
    font-family: futura-pt,Helvetica,arial,sans-serif;
    color: #444;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: -.3px;
    margin: 0 0 20px;
    text-align: center;
}

.btn--on-dark-hero.btn {
    min-height: 28px;
}

@media screen and (min-width: 768px) {
    .hero .hero__header {
        margin-top: 0;
    }

    .hero .hero__content-image:after {
        padding-top: 35%;
    }

    .hero .hero__content-wrapper {
        position: absolute;
        max-width: 1170px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .hero .hero__content-text {
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .hero.hero--on-dark:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0,0,0,0.2);
    }

    .hero.hero--on-dark .hero__content-wrapper {
        z-index: 1;
    }

    .hero.hero--on-dark .hero__header, 
    .hero.hero--on-dark .ingress {
        color: #fff;
    }

    .btn--on-dark-hero.btn {
        color: #fff;
        border: 1px solid #fff;
    }

    .btn--on-dark-hero.btn--arrow-right:before {
        border: 2px solid #fff;
    }

    .btn--on-dark-hero.btn--arrow-right:after {
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
    }

    .btn--on-dark-hero.btn--arrow-right:active, 
    .btn--on-dark-hero.btn--arrow-right:focus, 
    .btn--on-dark-hero.btn--arrow-right:hover {
        color: hsla(0,0%,100%,.8);
        background: none;
    }

    .btn--on-dark-hero.btn--arrow-right:active:after, 
    .btn--on-dark-hero.btn--arrow-right:active:before, 
    .btn--on-dark-hero.btn--arrow-right:focus:after, 
    .btn--on-dark-hero.btn--arrow-right:focus:before, 
    .btn--on-dark-hero.btn--arrow-right:hover:after, 
    .btn--on-dark-hero.btn--arrow-right:hover:before {
        border-color: hsla(0,0%,100%,.7);
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .hero .hero__header {
        font-size: 36px;
    }
}

@media screen and (min-width: 1025px) {
	.hero .hero__header {
		font-size: 49px;
	}
}